/* TASKS CONTAINER */
.task-input-right {
  @include flexbox();
  gap: 0.5rem;
}

.weekday-container {
  @include flexbox(row, space-around, top);
  margin-top: 1.5rem;

  @include mq(mobile) {
    flex-direction: column;
    width: 80%;
    margin: 1rem auto;
  }
}

.weekday {
  border-left: 1px dotted var(--base);
  text-align: center;
  flex: 1 1 100px;

  &:first-child {
    border: none;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  @include mq(mobile) {
    margin-bottom: 0;
    border: none;
  }
}

.weekday-header h5,
.weekday-header h4 {
  margin: 0;
  font-size: 0.7rem;
  font-weight: 300;
}

.weekday-header h4 {
  font-size: 2rem;
}

.task {
  text-align: left;
  font-size: 0.65rem;
  margin: 0 1vw 0.4rem;
  position: relative;
  @include flexbox(row, flex-start, center);

  button {
    font-size: 0.7rem;
    padding: 0.05rem;
  }

  @include mq(mobile) {
    margin: 0.5rem 15vw 0;
  }
}

.delete-task {
  position: absolute;
  background-color: rgb(199, 85, 85);
  color: white;
}

.task-text {
  margin-left: 0.5rem;
  user-select: none;
  font-size: 1.2em;
}

.hide-check {
  color: white;
}

.hide-delete {
  display: none;
}

@include mq(mobile) {
  .task-container form {
    flex-direction: column;
  }

  .hide-weekday {
    display: none;
  }

  .weekday-header {
    border-bottom: 1px solid var(--base);
    @include flexbox(row, space-around, baseline);

    h5 {
      font-size: 1rem;
    }

    h4 {
      font-size: 1.5rem;
    }
  }
}
