/* screen sizes 900-1200px */
/* PAGE HEADER */
header {
  @include flexbox(row, space-between);
  margin: 0.5rem auto;
  width: 80%;

  @include mq(mobile) {
    flex-direction: column;
    margin-bottom: 2rem;
  }
}

.header-text,
.header-date-time {
  text-align: center;
}

.header-text {
  @include mq(tablet) {
    text-align: left;
  }
  flex: 1;

  h1 {
    font-family: "EB Garamond", serif;
    font-weight: 500;
    letter-spacing: 0.3rem;
    font-size: 3.5rem;
    margin: 0;
    color: var(--base);
  }

  p {
    margin: 0;
    @include mq(mobile) {
      margin: 0 auto 1.5rem;
    }

    @include res-width(80%, 80%, 70%, 50%, 50%);
  }
}

.underline {
  text-decoration: underline var(--base);
}

.header-date-time {
  @include mq(tablet) {
    text-align: right;
  }
  flex: 1;
  font-size: 1.3rem;
}

.header-time {
  font-size: 2rem;
  font-weight: 400;
  color: var(--base);
}

#color-picker {
  border-radius: 5px;
  margin-left: 0.5rem;
}

nav {
  @include flexbox(column);
  font-weight: 400;

  ul {
    padding: 0;
    margin: 0;
    @include flexbox();
    gap: 1.5rem;
  }

  > p {
    font-size: 0.6rem;
    letter-spacing: 0.1rem;
    margin-bottom: 0;
  }

  ul li {
    list-style: none;
    &:hover {
      text-shadow: 0 0.01em black, 0 -0.01em black, 0.01em 0 black,
        -0.01em 0 black;
      cursor: pointer;
    }
  }
}

.nav-link-active {
  text-decoration: underline var(--base);
}
