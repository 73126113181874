/* TASKS CONTAINER */
.task-input-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
}

.weekday-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: top;
      -ms-flex-align: top;
          align-items: top;
  margin-top: 1.5rem;
}

@media only screen and (max-width: 599px) {
  .weekday-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 80%;
    margin: 1rem auto;
  }
}

.weekday {
  border-left: 1px dotted var(--base);
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100px;
          flex: 1 1 100px;
}

.weekday:first-child {
  border: none;
}

.weekday ul {
  list-style: none;
  padding: 0;
}

@media only screen and (max-width: 599px) {
  .weekday {
    margin-bottom: 0;
    border: none;
  }
}

.weekday-header h5,
.weekday-header h4 {
  margin: 0;
  font-size: 0.7rem;
  font-weight: 300;
}

.weekday-header h4 {
  font-size: 2rem;
}

.task {
  text-align: left;
  font-size: 0.65rem;
  margin: 0 1vw 0.4rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.task button {
  font-size: 0.7rem;
  padding: 0.05rem;
}

@media only screen and (max-width: 599px) {
  .task {
    margin: 0.5rem 15vw 0;
  }
}

.delete-task {
  position: absolute;
  background-color: #c75555;
  color: white;
}

.task-text {
  margin-left: 0.5rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 1.2em;
}

.hide-check {
  color: white;
}

.hide-delete {
  display: none;
}

@media only screen and (max-width: 599px) {
  .task-container form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .hide-weekday {
    display: none;
  }
  .weekday-header {
    border-bottom: 1px solid var(--base);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
  .weekday-header h5 {
    font-size: 1rem;
  }
  .weekday-header h4 {
    font-size: 1.5rem;
  }
}

/* screen sizes 900-1200px */
/* PAGE HEADER */
header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0.5rem auto;
  width: 80%;
}

@media only screen and (max-width: 599px) {
  header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 2rem;
  }
}

.header-text,
.header-date-time {
  text-align: center;
}

.header-text {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

@media only screen and (min-width: 600px) {
  .header-text {
    text-align: left;
  }
}

.header-text h1 {
  font-family: "EB Garamond", serif;
  font-weight: 500;
  letter-spacing: 0.3rem;
  font-size: 3.5rem;
  margin: 0;
  color: var(--base);
}

.header-text p {
  margin: 0;
}

@media only screen and (max-width: 599px) {
  .header-text p {
    margin: 0 auto 1.5rem;
  }
}

@media only screen and (max-width: 599px) {
  .header-text p {
    width: 80%;
  }
}

@media only screen and (min-width: 600px) {
  .header-text p {
    width: 80%;
  }
}

@media only screen and (min-width: 900px) {
  .header-text p {
    width: 70%;
  }
}

@media only screen and (min-width: 1200px) {
  .header-text p {
    width: 50%;
  }
}

@media only screen and (min-width: 1800px) {
  .header-text p {
    width: 50%;
  }
}

.underline {
  -webkit-text-decoration: underline var(--base);
          text-decoration: underline var(--base);
}

.header-date-time {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 1.3rem;
}

@media only screen and (min-width: 600px) {
  .header-date-time {
    text-align: right;
  }
}

.header-time {
  font-size: 2rem;
  font-weight: 400;
  color: var(--base);
}

#color-picker {
  border-radius: 5px;
  margin-left: 0.5rem;
}

nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 400;
}

nav ul {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1.5rem;
}

nav > p {
  font-size: 0.6rem;
  letter-spacing: 0.1rem;
  margin-bottom: 0;
}

nav ul li {
  list-style: none;
}

nav ul li:hover {
  text-shadow: 0 0.01em black, 0 -0.01em black, 0.01em 0 black, -0.01em 0 black;
  cursor: pointer;
}

.nav-link-active {
  -webkit-text-decoration: underline var(--base);
          text-decoration: underline var(--base);
}

.habit-tracker {
  margin: 2rem auto;
  padding-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: normal;
      -ms-flex-align: normal;
          align-items: normal;
  gap: 1rem;
}

@media only screen and (max-width: 599px) {
  .habit-tracker {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .habit-tracker {
    width: 80%;
  }
}

@media only screen and (min-width: 900px) {
  .habit-tracker {
    width: 60%;
  }
}

@media only screen and (min-width: 1200px) {
  .habit-tracker {
    width: 50%;
  }
}

@media only screen and (min-width: 1800px) {
  .habit-tracker {
    width: 40%;
  }
}

.habit-header-dates {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1.5rem;
  border-bottom: 1px solid var(--base);
}

@media only screen and (max-width: 599px) {
  .habit-header-dates {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.habit-header-date {
  border: none;
  background-color: transparent;
  padding: 0;
  width: 1.2rem;
  height: 1.2rem;
}

.habit {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media only screen and (max-width: 599px) {
  .habit {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 0.7rem;
  }
}

.habit-content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 2rem;
  text-align: right;
}

@media only screen and (max-width: 599px) {
  .habit-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 0 0.4rem 0;
  }
}

@media only screen and (min-width: 600px) {
  .habit-content {
    margin-right: 1rem;
  }
}

.habit-checkbox-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1.5rem;
}

.habit-btns {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
  white-space: nowrap;
}

@media only screen and (max-width: 599px) {
  .habit-btns {
    margin: 0 0 0 1rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
}

.habit-btns i {
  margin-right: 0.5rem;
}

.habit-btns .bi-trash {
  color: var(--base);
}

.habit-checkbox {
  border: 2px solid var(--base);
  border-radius: 100px;
  padding: 0;
  width: 1.2rem;
  height: 1.2rem;
}

.habit-checkbox i {
  color: white;
  pointer-events: none;
}

.checkbox-fill {
  background-color: var(--base);
  border-color: white;
}

@media screen and (max-width: 330px) {
  .habit-container form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .habit-container form button[type="submit"] {
    display: inline;
    width: 30%;
    margin: 0 auto;
  }
}

:root {
  --base: #8cc0bd;
  --base-opaque: #8cc0bd50;
}

body {
  overflow-y: scroll;
}

.App {
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  margin-bottom: 3rem;
}

/* GENERIC CONTAINER STYLES */
section {
  background-color: var(--base-opaque);
  width: 95%;
  margin: 0 auto;
  padding: 0 0 1rem;
  border-radius: 50px;
}

.container-title {
  padding-top: 1rem;
  font-weight: 500;
}

button:hover,
i:hover {
  cursor: pointer;
}

.bi-star,
.bi-star-fill {
  color: goldenrod;
}

/* FORMS */
form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
}

button[type="submit"] {
  font-weight: 600;
  color: white;
  background-color: var(--base);
}

form input,
select,
button {
  font-size: 0.9rem;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  display: inline-block;
  background-color: white;
}

.star-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.text-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 3em;
}

@media only screen and (max-width: 599px) {
  .options {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 0;
  }
}

.sort,
.edit {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  letter-spacing: 0.05rem;
}

.sort button,
.edit button {
  padding: 0.2rem 0.4rem;
  margin-left: 0.5rem;
  font-size: 0.7rem;
  letter-spacing: 0.02rem;
}

.selected {
  background-color: var(--base);
  color: white;
}

.help-text {
  margin-top: 0.3rem;
  font-size: 0.7rem;
  letter-spacing: 0.1rem;
}

.priority {
  font-weight: 500;
}

.completed {
  text-decoration: line-through;
  opacity: 0.5;
}

.highlight-today {
  font-weight: 600 !important;
}
