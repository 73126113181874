.habit-tracker {
  margin: 2rem auto;
  padding-bottom: 2rem;
  @include flexbox(column, center, normal);
  gap: 1rem;

  @include res-width(100%, 80%, 60%, 50%, 40%);
}

.habit-header-dates {
  @include flexbox(row, right, center);
  gap: 1.5rem;
  border-bottom: 1px solid var(--base);

  @include mq(mobile) {
    justify-content: center;
  }
}

.habit-header-date {
  border: none;
  background-color: transparent;
  padding: 0;
  width: 1.2rem;
  height: 1.2rem;
}

.habit {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  @include mq(mobile) {
    flex-direction: column;
    margin-bottom: 0.7rem;
  }
}

.habit-content {
  flex-grow: 1;
  @include flexbox(row, space-between, center);
  margin-right: 2rem;
  text-align: right;

  @include mq(mobile) {
    @include flexbox(row-reverse, center);
    margin: 0 0 0.4rem 0;
  }

  @include mq(tablet) {
    margin-right: 1rem;
  }
}

.habit-checkbox-row {
  @include flexbox();
  gap: 1.5rem;
}

.habit-btns {
  display: inline-flex;
  margin-right: 1rem;
  white-space: nowrap;

  @include mq(mobile) {
    margin: 0 0 0 1rem;
    flex-direction: row-reverse;
  }
}

.habit-btns i {
  margin-right: 0.5rem;
}

.habit-btns .bi-trash {
  color: var(--base);
}

.habit-checkbox {
  border: 2px solid var(--base);
  border-radius: 100px;
  padding: 0;
  width: 1.2rem;
  height: 1.2rem;
}

.habit-checkbox i {
  color: white;
  pointer-events: none;
}

.checkbox-fill {
  background-color: var(--base);
  border-color: white;
}

@media screen and (max-width: 330px) {
  .habit-container form {
    @include flexbox(column);
  }

  .habit-container form button[type="submit"] {
    display: inline;
    width: 30%;
    margin: 0 auto;
  }
}
