@import "./_mixins";

@import "./_tasks";
@import "./_header";
@import "./_habits";

:root {
  --base: #8cc0bd;
  --base-opaque: #8cc0bd50;
}

body {
  overflow-y: scroll;
}

.App {
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  margin-bottom: 3rem;
}

/* GENERIC CONTAINER STYLES */
section {
  background-color: var(--base-opaque);
  width: 95%;
  margin: 0 auto;
  padding: 0 0 1rem;
  border-radius: 50px;
}

.container-title {
  padding-top: 1rem;
  font-weight: 500;
}

button:hover,
i:hover {
  cursor: pointer;
}

.bi-star,
.bi-star-fill {
  color: goldenrod;
}

/* FORMS */
form {
  @include flexbox();
  gap: 0.5rem;
}

button[type="submit"] {
  font-weight: 600;
  color: white;
  background-color: var(--base);
}

form input,
select,
button {
  font-size: 0.9rem;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  display: inline-block;
  background-color: white;
}

.star-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.text-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.options {
  @include flexbox();
  gap: 3em;

  @include mq(mobile) {
    flex-direction: column;
    gap: 0;
  }
}

.sort,
.edit {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  letter-spacing: 0.05rem;

  button {
    padding: 0.2rem 0.4rem;
    margin-left: 0.5rem;
    font-size: 0.7rem;
    letter-spacing: 0.02rem;
  }
}

.selected {
  background-color: var(--base);
  color: white;
}

.help-text {
  margin-top: 0.3rem;
  font-size: 0.7rem;
  letter-spacing: 0.1rem;
}

.priority {
  font-weight: 500;
}

.completed {
  text-decoration: line-through;
  opacity: 0.5;
}

.highlight-today {
  font-weight: 600 !important;
}
